import Component from '../Component';

class TabContent extends Component {
  constructor(options) {
    super(options);
    this.links = this.root.querySelectorAll('.tabs__link');
    this.contentBlocks = this.root.querySelectorAll('.tabs__content');
  }

  attachEventListeners() {
    this.links.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        // Remove Active from each link
        this.links.forEach((eachLink) => {
          eachLink.classList.remove('active');
        });

        // Remove Active from each content block
        this.contentBlocks.forEach((block) => {
          if (block.classList.contains('active')) {
            block.classList.remove('active');
          }
        });

        // Find the desired conent block
        this.selectedBlock = this.root.querySelector(
          `[data-content=${e.target.dataset.link}]`
        );
        // Add active class to content block
        this.selectedBlock.classList.add('active');

        // Add Active call the clicked link
        e.target.classList.add('active');
      });
    });
  }

  onLoadActions() {
    this.links[0].classList.add('active');
    this.contentBlocks[0].classList.add('active');
  }

  componentDidMount() {
    // bind any event listeners
    this.onLoadActions();
    this.attachEventListeners();
  }
}

export default TabContent;
