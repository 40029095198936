import $ from 'jquery';
import YouTubePlayer from 'youtube-player';

import Component from '../Component';

class YouTubeModal extends Component {
  componentDidMount() {
    this.handlePlayerReady = this.handlePlayerReady.bind(this);
    this.handlePlayerStateChange = this.handlePlayerStateChange.bind(this);

    this.attachEventListeners();
  }

  attachEventListeners() {
    const videoItem = $(this.root).find('.video-modal-link');
    videoItem.click((e) => {
      e.preventDefault();

      const videoUrl = $(this.root).data('videourl');
      const videoId = videoUrl.split('v=')[1];
      const videoTitle = $(this.root).data('videotitle');

      this.openModal(videoTitle);
      this.initVideo(videoId);
    });

    $('.video-modal .modal-close').click((e) => {
      e.preventDefault();
      this.stopVideo();
      this.closeModal();
    });

    $('.video-modal').click((e) => {
      const target = $(e.currentTarget);
      if (target.parents('.modal__box').length === 0) {
        e.preventDefault();
        this.stopVideo();
        this.closeModal();
      }
    });
  }

  escapeKey() {
    $(document).keyup((e) => {
      if (e.keyCode === 27) {
        this.stopVideo();
        this.closeModal();
      }
    });
  }

  initVideo(videoId) {
    const modalPlayerContainer = this.body.querySelector(
      '[data-ref="youtube-player"]'
    );
    this.player = YouTubePlayer(modalPlayerContainer, {
      videoId,
      playerVars: {
        rel: 0
      }
    });

    this.player.on('ready', this.handlePlayerReady);
    this.player.on('stateChange', this.handlePlayerStateChange);
  }

  openModal(videoTitle) {
    const modalWindow = $('.video-modal');

    modalWindow.addClass('open');
    modalWindow.find('.video-title').html(videoTitle);
    $('body').addClass('modal-open');
  }

  closeModal() {
    $('.modal__overlay').removeClass('open');
    $('body').removeClass('modal-open');
  }

  stopVideo() {
    if (this.player) {
      this.player.destroy();
    }
  }

  handlePlayerReady(e) {
    let orientationDevice = false;
    if (typeof window.orientation !== 'undefined') {
      orientationDevice = true;
    }

    if (!orientationDevice) {
      e.target.playVideo();
    }

    $(this.refs.videoContainer)
      .removeAttr('height')
      .removeAttr('width');
  }

  handlePlayerStateChange(e) {
    if (e.data === 0) {
      this.stopVideo();
      this.closeModal();
    }
  }
}

export default YouTubeModal;
