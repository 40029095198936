import Component from '../Component';

class HoverPlay extends Component {
  constructor(options) {
    super(options);

    this.state = {
      playing: false
    };
    this.video = this.root.querySelector('.video-background__video');
  }

  attachEventListeners() {
    this.root.addEventListener(
      'mouseover',
      () => {
        this.video.play();
      },
      true
    );
    this.root.addEventListener(
      'mouseleave',
      () => {
        this.video.pause();
      },
      true
    );
  }

  componentDidMount() {
    // bind any event listeners
    this.attachEventListeners();
  }
}

export default HoverPlay;
