import Swiper from 'swiper';

import Component from '../Component';

class Carousel extends Component {
  componentDidMount() {
    this.carousel = new Swiper(this.root, {
      grabCursor: true,
      setWrapperSize: true,
      slidesPerView: 4,
      navigation: {
        disabledClass: 'carousel-nav__button--disabled',
        hiddenClass: 'carousel-nav__button--hidden',
        nextEl: '.carousel-nav__button--next',
        prevEl: '.carousel-nav__button--prev'
      },
      breakpoints: {
        1024: {
          centeredSlides: false,
          slidesPerView: 4
        },
        768: {
          centeredSlides: false,
          slidesPerView: 3.5
        },
        640: {
          centeredSlides: true,
          slidesPerView: 1.2
        }
      },
      spaceBetween: 23
    });
  }
}

export default Carousel;
