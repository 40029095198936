import Nav from './components/Nav';
import HoverPlay from './components/HoverPlay';
import Carousel from './components/Carousel';
import FeatureCarousel from './components/FeatureCarousel';
import TabContent from './components/TabContent';
import ImagesFadeIn from './components/ImagesFadeIn';
import BackgroundFadeIn from './components/BackgroundFadeIn';
import Accordion from './components/Accordion';
import YouTube from './components/YouTube';
import YouTubeModal from './components/YouTubeModal';

// styles
import '../scss/utils/_reset.scss';
import '../scss/utils/_functions.scss';
import '../scss/utils/_mixins.scss';
import '../scss/utils/_variables.scss';

import '../scss/vendor/_badger-accordion.scss';
import '../scss/vendor/_swiper.min.scss';

import '../scss/generic/_typography.scss';
import '../scss/generic/_buttons.scss';
import '../scss/generic/_images.scss';
import '../scss/generic/_forms.scss';
import '../scss/generic/_layout.scss';

import '../scss/templates/_home.scss';
import '../scss/templates/_text.scss';

import '../scss/components/_footer.scss';
import '../scss/components/_header.scss';
import '../scss/components/_faqs.scss';
import '../scss/components/_term.scss';
import '../scss/components/_accordion.scss';
import '../scss/components/_waypoints-header.scss';
import '../scss/components/_header-image.scss';
import '../scss/components/_full-width-image.scss';
import '../scss/components/_full-grid-image.scss';
import '../scss/components/_nav.scss';
import '../scss/components/_rte.scss';
import '../scss/components/_video-background.scss';
import '../scss/components/_modal.scss';
import '../scss/components/_video-modal.scss';
import '../scss/components/_media-strip.scss';
import '../scss/components/_feature.scss';
import '../scss/components/_image-grid.scss';
import '../scss/components/_image-gallery.scss';
import '../scss/components/_two_cols_text.scss';
import '../scss/components/_page-headline-banner.scss';
import '../scss/components/_tabs.scss';
import '../scss/components/_cookie-declaration.scss';
import '../scss/components/_newsletter-signup.scss';
import '../scss/components/_youtube-embed.scss';
import '../scss/components/_sales-carousel.scss';
import '../scss/components/_timeline.scss';
import '../scss/components/_list-with-icons.scss';
import '../scss/components/_report-module.scss';
import '../scss/components/_next-event.scss';
import '../scss/components/_whats-new.scss';
import '../scss/components/_three-col-image-links.scss';
import '../scss/components/_two-image-strip.scss';
import '../scss/components/_block-image-with-text-grid.scss';
import '../scss/components/_sub-nav.scss';
import '../scss/components/_site-footer.scss';
import '../scss/components/_journal.scss';
import '../scss/components/_news-feed.scss';
import '../scss/components/_news-article.scss';
import '../scss/components/_pagination.scss';

// Additional Sass Files
import '../scss/components/_svg-grid.scss';
import '../scss/components/_full-width-video.scss';
import '../scss/components/_quote-with-icon.scss';
import '../scss/components/_three-col-image-text.scss';

import '../scss/app.scss';

class GentlemenOfTheRoad {
  constructor(components) {
    Object.keys(components).forEach((component) => {
      const Component = components[component];
      const elements = document.querySelectorAll(
        `[data-component="${component}"]`
      );

      [...elements].forEach((element) => {
        new Component({ root: element });
      });
    });
  }
}

new GentlemenOfTheRoad({
  youTube: YouTube,
  youTubeModal: YouTubeModal,
  accordion: Accordion,
  nav: Nav,
  carousel: Carousel,
  featureCarousel: FeatureCarousel,
  hoverPlay: HoverPlay,
  tabContent: TabContent,
  imagesFadeIn: ImagesFadeIn,
  backgroundFadeIn: BackgroundFadeIn
});
