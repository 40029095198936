import imagesLoaded from 'imagesloaded';
import Component from '../Component';

class ImagesFadeIn extends Component {
  attachEventListeners() {
    imagesLoaded(this.root, () => {
      this.root.classList.add('imagesLoaded');
    });
  }

  componentDidMount() {
    // bind any event listeners
    this.attachEventListeners();
  }
}
export default ImagesFadeIn;
