import imagesLoaded from 'imagesloaded';
import Component from '../Component';

class BackgroundFadeIn extends Component {
  attachEventListeners() {
    imagesLoaded(this.root, { background: true }, () => {
      this.root.classList.remove('no-background');
    });
  }

  componentDidMount() {
    // bind any event listeners
    this.attachEventListeners();
  }
}
export default BackgroundFadeIn;
