/* global YT */
import Component from '../Component';

class YouTube extends Component {
  constructor(options) {
    super(options);
    this.loadYoutubeApi();
    this.videoID = this.root.getAttribute('data-video-id');

    function onPlayerReady() {
      // bind events
      this.root.addEventListener('click', () => {
        this.player.playVideo();
        this.root.classList.add('playing');
      });
    }

    function onPlayerStateChange(event) {
      console.log(event.data);
      setTimeout(() => {
        if (event.data === 2) {
          this.root.classList.remove('playing');
        } else {
          this.root.classList.add('playing');
        }
      }, 500);
    }
    global.onYouTubeIframeAPIReady = () => {
      this.player = new YT.Player('player', {
        height: '360',
        width: '640',
        videoId: this.videoID,
        playerVars: { rel: 0 },
        events: {
          onReady: onPlayerReady.bind(this),
          onStateChange: onPlayerStateChange.bind(this)
        }
      });
    };
  }

  loadYoutubeApi() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
}

export default YouTube;
