import BadgerAccordion from 'badger-accordion';
import Component from '../Component';

class Accordion extends Component {
  constructor(options) {
    super(options);
    this.accordion = new BadgerAccordion(this.root);
  }
}
export default Accordion;
