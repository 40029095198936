import Swiper from 'swiper';

import Component from '../Component';

class FeatureCarousel extends Component {
  componentDidMount() {
    this.carousel = new Swiper(this.root, {
      grabCursor: true,
      setWrapperSize: true,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });
  }
}

export default FeatureCarousel;
