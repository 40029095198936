import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Component from '../Component';

class Nav extends Component {
  constructor(options) {
    super(options);

    this.state = {
      isOpen: false
    };

    // the nav container is the previous sibling so store it in a var
    this.navContainer = this.root.previousElementSibling;
    this.header = this.body.querySelector('[data-ref="header"]');
  }

  toggleNavigation() {
    this.state.isOpen = !this.state.isOpen;
    this.body.classList.toggle('navigation-open');
    this.html.classList.toggle('navigation-open');
    this.root.classList.toggle('nav-button--open', !this.state.isOpen);
    this.root.classList.toggle('nav-button--close', this.state.isOpen);
    this.refs.text[0].textContent = this.state.isOpen ? 'Close' : 'Open';
    if (this.state.isOpen) {
      const firstNavItem = this.navContainer.querySelector('a');
      disableBodyScroll(this.navContainer);
      firstNavItem.focus();
    } else {
      enableBodyScroll(this.navContainer);
    }
  }

  handleTouchMove(e) {
    console.log('Handle Touch');
    e.preventDefault();
  }

  attachEventListeners() {
    // add a click listener to the nav open/close button
    this.root.addEventListener(
      'click',
      () => {
        this.toggleNavigation();
      },
      true
    );

    document.addEventListener(
      'keyup',
      (e) => {
        if (this.state.isOpen && e.keyCode === 27) this.toggleNavigation();
      },
      true
    );
  }

  componentDidMount() {
    // bind any event listeners
    this.attachEventListeners();
  }
}

export default Nav;
